<script setup>
import AuthNav from "@/components/ui/AuthNav.vue";
import DocumentsSidebar from "@/components/ui/DocumentsSidebar.vue";
import { ref, onMounted, onUnmounted, nextTick } from "vue";

const navHeight = ref(0);
//const sidebarWidth = ref(226); // Gonna have to fix this maybe someday. For now hardcoded since the links are forced to be the same size

const updateDimensions = async () => {
  await nextTick();
  navHeight.value = document.getElementById("nav").offsetHeight;
  //sidebarWidth.value = document.getElementById("sidebar").offsetWidth;
};

onMounted(() => {
  updateDimensions();

  const onResize = () => {
    nextTick(updateDimensions);
  };

  window.addEventListener("resize", onResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateDimensions);
});
</script>

<template>
  <div>
    <AuthNav />
    <main>
      <div class="flex flex-row">
        <DocumentsSidebar />
        <div
          class="flex-grow p-8 ml-2 md:ml-[226px]"
          :style="{
            marginTop: `${navHeight}px`,
          }"
        >
          <router-view></router-view>
        </div>
      </div>
    </main>
    <footer></footer>
  </div>
</template>
