<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

import CategoryDocuments from "@/components/ui/CategoryDocuments.vue";

const store = useStore();

const categories = computed(() => store.state.categories);
const documents = computed(() => store.state.documents);

const filteredCategories = computed(() => {
  return categories.value.filter((category) =>
    documents.value.some((document) =>
      document.categories.some((docCategory) => docCategory.id === category.id)
    )
  );
});

onMounted(async () => {
  await store.dispatch("fetchCategories");
  await store.dispatch("fetchDocuments");
});
</script>

<template>
  <div class="flex flex-col gap-4">
    <CategoryDocuments
      v-for="category in filteredCategories"
      :key="category.id"
      :category="category"
      :documents="documents"
    />
  </div>
</template>
