<script setup>
import { ref, onMounted, onUnmounted, computed, nextTick } from "vue";
// import axios from "axios";
import { useStore } from "vuex";

import PlusIcon from "@/components/icons/PlusIcon.vue";
import AngleIcon from "../icons/AngleIcon.vue";

const store = useStore();

const sidebar = ref(null);
const toggleButton = ref(null);
const navHeight = ref(0);
const viewportHeight = ref(window.innerHeight);
const sidebarVisible = ref(false);

const user = computed(() => store.getters.currentUser);

const sidebarHeight = computed(() => {
  return `${viewportHeight.value - navHeight.value}px`;
});

const documents = computed(() => store.state.documents);

const updateDimensions = () => {
  if (document.getElementById("nav")) {
    navHeight.value = document.getElementById("nav").clientHeight;
  }
  viewportHeight.value = window.innerHeight;
};

const toggleSidebar = () => {
  sidebarVisible.value = !sidebarVisible.value;
};

const handleGlobalClick = (e) => {
  if (
    sidebarVisible.value &&
    sidebar.value &&
    !sidebar.value.contains(e.target) &&
    !toggleButton.value.contains(e.target)
  ) {
    sidebarVisible.value = false;
  }
};

onMounted(() => {
  updateDimensions();

  const onResize = () => {
    nextTick(updateDimensions);
  };

  window.addEventListener("resize", onResize);
  document.addEventListener("click", handleGlobalClick);

  store.dispatch("fetchDocuments");
});

onUnmounted(() => {
  window.removeEventListener("resize", updateDimensions);
  document.removeEventListener("click", handleGlobalClick);
});
</script>

<template>
  <button
    @click="toggleSidebar"
    ref="toggleButton"
    :class="sidebarVisible ? 'left-[226px]' : 'left-0'"
    class="fixed left-0 md:hidden z-30 py-4 px-0.5 bg-paper rounded-r-md border-2 border-l-0 border-black transition-[left] duration-300 ease-linear"
    :style="{ top: `${navHeight + 24}px` }"
  >
    <AngleIcon class="rotate-90" />
  </button>
  <div
    id="sidebar"
    ref="sidebar"
    :class="sidebarVisible ? 'left-0' : 'left-[-226px]'"
    class="px-4 py-8 fixed top-7 md:left-0 border-r-2 border-t-2 border-black border-box bg-paper transition-[left] duration-300 ease-linear"
    :style="{ height: sidebarHeight, top: `${navHeight}px` }"
  >
    <h3 class="text-xl font-bold mb-4 px-2 inline-flex gap-2">
      <Router-Link
        v-if="user?.is_admin"
        :to="{ name: 'document-add' }"
        class="h-4"
      >
        <PlusIcon class="inline h-4 mb-1"
      /></Router-Link>
      <RouterLink :to="{ name: 'app' }">Documents</RouterLink>
    </h3>
    <div class="h-full pb-7 overflow-y-scroll flex flex-col gap-4">
      <RouterLink
        v-for="document in documents"
        v-bind:key="document.id"
        :to="{ name: 'document', params: { id: document.id } }"
        class="truncate w-48 px-2 py-2 hover:bg-gray-300 rounded-md transition-colors duration-300 h-10"
        :title="document.title"
        @click="toggleSidebar"
        >{{ document.title }}</RouterLink
      >
    </div>
  </div>
</template>
