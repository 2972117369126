<script setup>
import {
  defineProps,
  defineEmits,
  ref,
  onMounted,
  watch,
  nextTick,
  computed,
} from "vue";
import axios from "axios";
import { useStore } from "vuex";

import SubmitButton from "@/components/ui/SubmitButton.vue";

import SpinnerIcon from "../icons/SpinnerIcon.vue";

const store = useStore();
const token = computed(() => store.state.token);

const props = defineProps({
  section: Object,
  roles: Array,
});

const emit = defineEmits(["toggleEditing"]);

const toggleEditing = () => {
  emit("toggleEditing");
};

const discardChanges = () => {
  toggleEditing();
};

const setRole = (id) => {
  const index = form.value.roles.indexOf(id);
  if (index == -1) {
    form.value.roles.push(id);
  } else {
    form.value.roles.splice(index, 1);
  }
};

const contentHeight = ref("auto");
const content = ref(null);

const isSubmitting = ref(false);
const submissionError = ref("");

const form = ref({
  title: "",
  content: "",
  roles: [],
  order: "",
});

const adjustHeight = () => {
  if (content.value) {
    content.value.style.height = content.value.scrollHeight + 2 + "px";
  }
};

const handleSubmit = async () => {
  if (isSubmitting.value) {
    return;
  }

  isSubmitting.value = true;
  submissionError.value = "";

  const headers = {
    headers: {
      Authorization: `Bearer ${token.value}`,
    },
  };
  try {
    const response = await axios.put(
      `/sections/${props.section.id}`,
      form.value,
      headers
    );
    const updatedSection = response.data;
    store.commit("UPDATE_SECTION", {
      sectionId: props.section.id,
      updatedSection,
    });
    toggleEditing();
  } catch (error) {
    console.error("Error updating section: ", error);
    submissionError.value =
      error.response?.data?.message || "An unknown error occured";
  } finally {
    isSubmitting.value = false;
  }
};

onMounted(() => {
  form.value = {
    title: props.section.title,
    content: props.section.content,
    roles: props.section.roles.map((role) => role.id),
    order: props.section.order,
    document_id: props.section.document_id,
  };
  nextTick(() => {
    adjustHeight();
  });
});

watch(
  () => form.value,
  () => {
    nextTick(() => {
      adjustHeight();
    });
  },
  { deep: true, immediate: true }
);
</script>

<template>
  <div class="max-w-4xl px-4 bg-gray-200 py-2 pb-2.5 rounded-md">
    <form @submit.prevent="handleSubmit">
      <div class="flex flex-wrap gap-2 mb-1">
        <span
          v-for="role in roles"
          :key="role.id"
          @click="setRole(role.id)"
          :class="
            form.roles.includes(role.id)
              ? 'bg-black text-paper'
              : 'bg-gray-300 text-black'
          "
          class="px-2 py-0.5 text-xs rounded-md transition-colors duration-300 hover:bg-black hover:text-paper cursor-pointer"
        >
          {{ role.acronym }}
        </span>
      </div>
      <input
        id="title"
        type="text"
        v-model="form.title"
        placeholder="Untitled Section"
        class="bg-gray-200 text-2xl border-b-2 border-transparent font-bold focus:outline-0 focus:border-gray-500 w-full mb-2 hover:border-b-2 box-content hover:border-b-black"
      />
      <textarea
        id="content"
        ref="content"
        v-model="form.content"
        placeholder="Start adding content here."
        rows="1"
        class="bg-gray-200 text-base focus:outline-0 focus:border-gray-500 w-full mb-2 resize-none overflow-y-hidden hover:border-b-2 hover:border-b-black"
        style="height: auto"
        :style="{
          height: contentHeight,
        }"
      />
      <div class="flex gap-4 flex-row mb-2">
        <SubmitButton
          :is-disabled="form.title.trim() === '' || isSubmitting"
          :small="true"
        >
          <span v-if="!isSubmitting">Submit</span>
          <SpinnerIcon v-if="isSubmitting" class="animate-spin" />
        </SubmitButton>
        <button
          @click.prevent="discardChanges"
          class="px-2 py-0.5 text-sm bg-paper border-2 border-black rounded-md hover:text-paper hover:bg-black duration-300 transition-colors cursor-pointer"
        >
          Discard Changes
        </button>
      </div>
      <div v-if="submissionError" class="text-red-700 text-xs">
        {{ submissionError }}
      </div>
    </form>
  </div>
</template>
