<script setup>
import { defineProps, defineEmits, ref, computed } from "vue";
import axios from "axios";
import { useStore } from "vuex";

import SectionForm from "@/components/forms/SectionForm.vue";
import ConfirmDelete from "../ui/ConfirmDelete.vue";

import PencilIcon from "../icons/PencilIcon.vue";
import TrashIcon from "../icons/TrashIcon.vue";

const props = defineProps({
  section: Object,
  selectedRoleId: [Number, String],
  user: Object,
  roles: Array,
});

const store = useStore();
const token = computed(() => store.state.token);

const showDeleteConfirmation = ref(false);

const emit = defineEmits(["updateSelectedRoleId"]);

const changeSelectedRoleId = (roleId) => {
  emit("updateSelectedRoleId", roleId);
};

const cancelDeletion = () => {
  showDeleteConfirmation.value = false;
};

const requestDeletion = () => {
  showDeleteConfirmation.value = true;
};

const deleteSection = async () => {
  showDeleteConfirmation.value = false;
  try {
    const headers = {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    };
    await axios.delete(`/sections/${props.section.id}`, headers);
    store.commit("DELETE_SECTION", { sectionId: props.section.id });
  } catch (error) {
    console.error("Error updating section: ", error);
  }
};

const isEditing = ref(false);
</script>

<template>
  <div class="w-full">
    <div
      v-if="!isEditing"
      :class="
        section.roles.some((role) => role.id == selectedRoleId)
          ? 'bg-yellow-50 pl-4 pr-4'
          : 'pr-8'
      "
      class="max-w-4xl py-2 pb-2.5 rounded-md hover:bg-yellow-50 hover:pl-4 hover:pr-4 transition-all duration-300"
    >
      <div v-if="section.roles" class="flex flex-wrap gap-2 mb-1">
        <span
          v-for="role in section.roles"
          :key="role.id"
          @click="changeSelectedRoleId(role.id)"
          :class="
            selectedRoleId == role.id
              ? 'bg-black text-paper'
              : 'bg-gray-200 text-black'
          "
          class="px-2 py-0.5 text-xs rounded-md transition-colors duration-300 hover:bg-black hover:text-paper cursor-pointer"
        >
          {{ role.acronym }}
        </span>
      </div>
      <div class="inline-flex gap-3 items-center mb-[6px]">
        <h2 class="text-2xl font-bold mb-1">
          {{ section.title }}
        </h2>
        <span>
          <PencilIcon
            v-if="user?.is_admin || user?.is_editor"
            @click="isEditing = true"
            class="cursor-pointer"
          />
        </span>
        <span>
          <TrashIcon
            v-if="user?.is_admin || user?.is_editor"
            @click="requestDeletion"
            class="text-red-800 cursor-pointer"
          />
        </span>
      </div>
      <p>{{ section.content }}</p>
    </div>
    <SectionForm
      v-if="isEditing"
      @toggleEditing="isEditing = false"
      :section="section"
      :roles="roles"
    />
    <ConfirmDelete
      v-if="showDeleteConfirmation"
      @confirm="deleteSection"
      @cancel="cancelDeletion"
      item="Section"
    />
  </div>
</template>
