<script setup>
import { defineProps, computed, ref } from "vue";
import DocumentCard from "./DocumentCard.vue";
import AngleIcon from "@/components/icons/AngleIcon.vue";

const props = defineProps({
  category: Object,
  documents: [Array, null],
});

const closed = ref(false);

const filteredDocuments = computed(() => {
  return props.documents.filter((document) => {
    return document.categories.some(
      (docCategory) => docCategory.id === props.category.id
    );
  });
});
</script>

<template>
  <div class="max-w-4xl bg-gray-200 px-4 py-2 pb-4 rounded-md">
    <div class="flex flex-row justify-between items-center mb-2 px-2">
      <h2 class="text-4xl font-bold">{{ props.category.name }}</h2>
      <div @click="closed = !closed" class="cursor-pointer">
        <AngleIcon :class="closed ? 'rotate-180' : 'rotate-0'" class="h-6" />
      </div>
    </div>
    <div v-if="!closed" class="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <DocumentCard
        v-for="document in filteredDocuments"
        :key="document.id"
        :document="document"
      />
    </div>
  </div>
</template>
