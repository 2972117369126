<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  document: Object,
});

const formattedUpdatedAt = computed(() => {
  if (props.document.updated_at) {
    const date = new Date(props.document.updated_at);
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  }
  return "";
});
</script>

<template>
  <RouterLink :to="{ name: 'document', params: { id: props.document.id } }">
    <div
      class="bg-paper rounded-md p-3 py-2 max-h-[140px]"
      :title="document.title"
    >
      <h3 class="text-xl font-bold mb-1 line-clamp-1 text-ellipsis">
        {{ document.title }}
      </h3>
      <p class="line-clamp-3 text-ellipsis text-sm pl-[1px] mb-4 h-[60px]">
        {{ document.description }}
      </p>
      <p class="text-xs line-clamp-1 text-ellipsis">
        Updated
        <span v-if="props.document.last_modified_user">
          by {{ props.document.last_modified_user.name }}</span
        >
        on {{ formattedUpdatedAt }}
      </p>
    </div>
  </RouterLink>
</template>
