<script setup>
import { defineProps, onMounted, ref, computed, defineEmits } from "vue";
import { useStore } from "vuex";
import axios from "axios";

import SubmitButton from "../ui/SubmitButton.vue";
import ConfirmDelete from "../ui/ConfirmDelete.vue";

import CheckMarkIcon from "@/components/icons/CheckMarkIcon.vue";
import AngleIcon from "../icons/AngleIcon.vue";
import SpinnerIcon from "../icons/SpinnerIcon.vue";
import TrashIcon from "../icons/TrashIcon.vue";
import router from "@/router";

const props = defineProps({
  user: Object,
  updatePassword: Boolean,
});

const authUser = computed(() => store.getters.currentUser);

const emit = defineEmits(["submitted"]);

const store = useStore();
const token = computed(() => store.state.token);

const form = ref({
  name: "",
  email: "",
  password: "",
  is_admin: false,
  is_editor: false,
  role_id: "",
});

const roles = computed(() => store.state.roles);

const isSubmitting = ref(false);
const submissionError = ref("");

const showDeleteConfirmation = ref(false);

const cancelDeletion = () => {
  showDeleteConfirmation.value = false;
};

const requestDeletion = () => {
  showDeleteConfirmation.value = true;
};

const deleteUser = async () => {
  showDeleteConfirmation.value = false;

  const headers = {
    headers: {
      Authorization: `Bearer ${token.value}`,
    },
  };
  try {
    await axios.delete(`/users/${props.user.id}`, headers);
    store.commit("DELETE_USER", { userId: props.user.id });
    if (authUser.value.id == props.user.id) {
      store.dispatch("logout");
      router.push("/login");
    }
    emit("submitted");
  } catch (error) {
    console.error("Error deleting section: ", error);
  }
};

const handleSubmit = async () => {
  if (isSubmitting.value) {
    return;
  }

  isSubmitting.value = true;
  submissionError.value = "";

  const headers = {
    headers: {
      Authorization: `Bearer ${token.value}`,
    },
  };

  try {
    if (props.user) {
      let response = await axios.put(
        `/users/${props.user.id}`,
        form.value,
        headers
      );
      store.commit("UPDATE_USER", {
        userId: props.user.id,
        updatedUser: response.data,
      });
    } else {
      let response = await axios.post("/users", form.value, headers);
      store.commit("ADD_USER", response.data);
      form.value = {
        name: "",
        email: "",
        password: "",
        is_admin: false,
        is_editor: false,
        role_id: "",
      };
    }
    emit("submitted");
  } catch (error) {
    console.error("Error submitting user: ", error);
    submissionError.value =
      error.response?.data?.message || "An unknown error occured";
  } finally {
    isSubmitting.value = false;
  }
};

onMounted(async () => {
  if (props.user) {
    form.value.name = props.user.name;
    form.value.email = props.user.email;
    form.value.is_admin = props.user.is_admin;
    form.value.is_editor = props.user.is_editor;
    form.value.role_id = props.user.role_id;
  }

  await store.dispatch("fetchRoles");
});
</script>

<template>
  <div>
    <form
      @submit.prevent="handleSubmit"
      class="grid grid-cols-1 md:grid-cols-2 gap-4"
    >
      <div class="">
        <label :for="props.user ? 'name' : 'name-edit'" class="block text-xs"
          >Full Name</label
        >
        <input
          :id="props.user ? 'name' : 'name-edit'"
          type="text"
          v-model="form.name"
          placeholder="John Doe"
          class="px-2 py-1 rounded-md border-2 border-black bg-paper text-sm focus:outline-0 focus:border-gray-500 w-full"
        />
      </div>
      <div class="">
        <label :for="props.user ? 'email' : 'email-edit'" class="block text-xs"
          >Email</label
        >
        <input
          :id="props.user ? 'email' : 'email-edit'"
          type="email"
          v-model="form.email"
          placeholder="john.doe@stephensdirect.com"
          class="px-2 py-1 rounded-md border-2 border-black bg-paper text-sm focus:outline-0 focus:border-gray-500 w-full"
        />
      </div>
      <div class="">
        <label
          :for="props.user ? 'password' : 'password-edit'"
          class="block text-xs"
          >Password</label
        >
        <input
          :id="props.user ? 'password' : 'password-edit'"
          type="password"
          :disabled="props.user && !props.updatePassword"
          v-model="form.password"
          placeholder="············"
          class="px-2 py-1 rounded-md border-2 border-black bg-paper text-sm focus:outline-0 focus:border-gray-500 w-full disabled:bg-gray-300 disabled:cursor-not-allowed"
        />
      </div>
      <div class="">
        <label class="block text-xs">Permissions</label>
        <div class="flex gap-8 py-1 items-center">
          <label
            :for="props.user ? 'isEditor' : 'isEditor-edit'"
            class="flex items-center cursor-pointer"
          >
            <input
              type="checkbox"
              :id="props.user ? 'isEditor' : 'isEditor-edit'"
              v-model="form.is_editor"
              :checked="form.is_editor"
              class="peer opacity-0 absolute h-0 w-0"
            />
            <span
              class="peer-checked:bg-black border-2 border-black rounded-sm w-4 h-4 mr-2 flex items-center bg-paper justify-center"
            >
              <CheckMarkIcon class="text-paper"
            /></span>
            Editor
          </label>
          <label
            :for="props.user ? 'isAdmin' : 'isAdmin-edit'"
            class="flex items-center cursor-pointer"
          >
            <input
              type="checkbox"
              :id="props.user ? 'isAdmin' : 'isAdmin-edit'"
              :checked="form.is_admin"
              v-model="form.is_admin"
              class="peer opacity-0 absolute h-0 w-0"
            />
            <span
              class="peer-checked:bg-black border-2 border-black rounded-sm w-4 h-4 mr-2 flex items-center bg-paper justify-center"
              ><CheckMarkIcon class="text-paper"
            /></span>
            Administrator
          </label>
        </div>
      </div>
      <div class="relative z-10">
        <label :for="props.user ? 'roles' : 'roles-edit'" class="block text-xs"
          >Select Role</label
        >
        <select
          :id="props.user ? 'roles' : 'roles-edit'"
          v-model="form.role_id"
          class="px-2 pr-5 py-1 rounded-md border-2 w-full bg-paper border-black text-black text-sm appearance-none cursor-pointer"
        >
          <option value="" :selected="!props.user" disabled>Role</option>
          <option
            v-for="role in roles"
            v-bind:key="role.id"
            :value="role.id"
            :title="role.title"
          >
            {{ role.title }}
          </option>
        </select>
        <AngleIcon
          class="rotate-180 absolute w-2.5 right-2 bottom-2 pointer-events-none"
        />
      </div>
      <div class="inline-flex flex-row gap-4 items-center sm:mt-4 mt-0">
        <SubmitButton
          :is-disabled="
            form.name.trim() === '' ||
            form.email.trim() === '' ||
            (form.password.trim() === '' && !props.user) ||
            form.role_id === '' ||
            isSubmitting
          "
        >
          <span v-if="!isSubmitting">Submit</span>
          <SpinnerIcon v-if="isSubmitting" class="animate-spin" />
        </SubmitButton>
        <button
          v-if="props.user"
          @click.prevent="emit('submitted')"
          class="bg-paper border-2 border-black text-black rounded-md px-4 py-[2px] hover:text-paper hover:bg-black duration-300 transition-colors"
        >
          Clear User
        </button>
        <span>
          <TrashIcon
            v-if="props.user"
            @click="requestDeletion"
            class="text-red-800 cursor-pointer h-5"
          />
        </span>
      </div>
    </form>
    <ConfirmDelete
      v-if="showDeleteConfirmation && props.user"
      @confirm="deleteUser"
      @cancel="cancelDeletion"
      :item="`user ${props.user.name}`"
    />
  </div>
</template>

<style scoped>
input[type="checkbox"]:checked + .custom-checkbox {
  background-color: #000000;
}

input[type="checkbox"]:checked + .custom-checkbox::after {
  content: "✓";
  color: white;
}
</style>
