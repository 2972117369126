import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/auth/LoginView.vue";
import DocumentView from "@/views/documents/DocumentView.vue";
import CategoryView from "@/views/categories/CategoryView.vue";
import DocumentAddView from "@/views/documents/DocumentAddView.vue";
import DocumentEditView from "@/views/documents/DocumentEditView.vue";
import AdminView from "@/views/auth/AdminView.vue";
import AppView from "@/views/AppView.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import store from "@/store";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/app",
    component: AuthLayout,
    meta: { requiresAuth: true },
    children: [
      { path: "", name: "app", component: AppView },
      { path: "documents/:id", name: "document", component: DocumentView },
      {
        path: "documents/add",
        name: "document-add",
        component: DocumentAddView,
        meta: { requiresAdmin: true },
      },
      {
        path: "documents/:id/edit",
        name: "document-edit",
        component: DocumentEditView,
        meta: { requiresAdmin: true },
      },
      { path: "categories/:id", name: "category", component: CategoryView },
      {
        path: "admin",
        name: "admin",
        component: AdminView,
        meta: { requiresAdmin: true },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFoundView },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  const isLoggedIn = store.getters.isLoggedIn;
  const isAdmin = store.getters.isAdmin;

  if (requiresAuth && !isLoggedIn) {
    next("/login");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/app");
  } else if (requiresAdmin && !isAdmin) {
    next("/app");
  } else {
    next();
  }
});

export default router;
