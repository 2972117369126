<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { useStore } from "vuex";

import DocumentSection from "@/components/ui/DocumentSection.vue";
import ConfirmDelete from "@/components/ui/ConfirmDelete.vue";

import AngleIcon from "@/components/icons/AngleIcon.vue";
import PencilIcon from "@/components/icons/PencilIcon.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import router from "@/router";

const route = useRoute();
const store = useStore();
const token = ref(store.state.token);

const roles = ref([]);

const selectedRoleId = ref("");

const user = computed(() => store.getters.currentUser);

const showDeleteConfirmation = ref(false);

const isChangeOrder = ref(false);

const cancelDeletion = () => {
  showDeleteConfirmation.value = false;
};

const requestDeletion = () => {
  showDeleteConfirmation.value = true;
};

const deleteDocument = async () => {
  showDeleteConfirmation.value = false;

  const headers = {
    headers: {
      Authorization: `Bearer ${token.value}`,
    },
  };
  try {
    await axios.delete(`/documents/${route.params.id}`, headers);
    store.commit("DELETE_DOCUMENT", { documentId: route.params.id });
    router.push({ name: "app" });
  } catch (error) {
    console.error("Error updating section: ", error);
  }
};

const fetchRoles = async () => {
  try {
    const response = await axios.get("/roles", {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    });
    roles.value = response.data;
  } catch (error) {
    console.error("Error fetching roles: ", error);
  }
};

const moveSection = (index, direction) => {
  const newIndex = index + direction;
  if (newIndex < 0 || newIndex >= document.value.sections.length) return;

  const movedSection = document.value.sections.splice(index, 1)[0];
  document.value.sections.splice(newIndex, 0, movedSection);

  updateSectionOrder();
};

const updateSectionOrder = async () => {
  const sectionOrder = document.value.sections.map((sec) => sec.id);

  const headers = {
    headers: {
      Authorization: `Bearer ${token.value}`,
    },
  };

  try {
    await axios.put(
      `/documents/${document.value.id}/sections/order`,
      {
        sectionOrder,
      },
      headers
    );
  } catch (error) {
    console.error("Error updating section order: ", error);
  }
};

const formattedUpdatedAt = computed(() => {
  if (document.value.updated_at) {
    const date = new Date(document.value.updated_at);
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
  return "";
});

const changeSelectedRoleId = (id) => {
  if (selectedRoleId.value == id) {
    selectedRoleId.value = "";
  } else {
    selectedRoleId.value = id;
  }
};

const document = computed(() => store.state.currentDocument);

const addSection = async () => {
  const section = {
    title: "Untitled Section",
    content: "Start adding content here.",
    document_id: route.params.id,
  };

  const headers = {
    headers: {
      Authorization: `Bearer ${token.value}`,
    },
  };

  try {
    await axios.post("/sections", section, headers);
    await store.dispatch("fetchDocument", route.params.id);
  } catch (error) {
    console.error("Error creating section: ", error);
  }
};

onMounted(() => {
  if (user.value) {
    selectedRoleId.value = user.value.role_id;
  }
});

watch(
  user,
  (newValue) => {
    if (newValue && newValue.role_id && roles.value.length > 0) {
      selectedRoleId.value = newValue.role_id;
    }
  },
  { immediate: true }
);

watch(
  () => route.params.id,
  async (newId) => {
    if (newId) {
      try {
        await store.dispatch("fetchDocument", newId);
        await fetchRoles();
      } catch (error) {
        if (error.response && error.response.status === 404) {
          router.push({ name: "NotFound" });
        } else {
          console.error("Error fetching document: ", error);
        }
      }
    }
  },
  { immediate: true }
);
</script>

<template>
  <div class="mb-8">
    <div class="flex flex-row justify-between items-start w-full">
      <div v-if="document.categories" class="flex flex-wrap gap-2 mb-4">
        <RouterLink
          v-for="category in document.categories"
          :key="category.id"
          :to="{ name: 'category', params: { id: category.id } }"
          class="px-4 py-1 bg-gray-300 font-bold text-xs rounded-md hover:bg-black hover:text-white transition-colors duration-300"
        >
          {{ category.name }}
        </RouterLink>
      </div>
      <div class="relative">
        <select
          v-model="selectedRoleId"
          class="px-2 pr-5 py-1 bg-transparent rounded-md border-2 border-black text-black text-sm appearance-none cursor-pointer"
        >
          <option value="">N/A</option>
          <option
            v-for="role in roles"
            v-bind:key="role.id"
            :value="role.id"
            :title="role.name"
          >
            {{ role.acronym }}
          </option>
        </select>
        <AngleIcon
          class="rotate-180 absolute w-2.5 right-2 top-2 pointer-events-none"
        />
      </div>
    </div>
    <div class="inline-flex gap-3 items-center">
      <h1 class="text-4xl font-bold mb-1">{{ document.title }}</h1>
      <Router-Link
        v-if="user?.is_admin"
        :to="{ name: 'document-edit', params: { id: document.id } }"
      >
        <PencilIcon />
      </Router-Link>
      <span>
        <TrashIcon
          v-if="user?.is_admin"
          @click="requestDeletion"
          class="text-red-800 cursor-pointer"
        />
      </span>
    </div>
    <p class="text-sm px-1 mb-4 text-gray-500">
      Last updated
      <span v-if="document.last_modified_user">
        by {{ document.last_modified_user.name }}</span
      >
      on {{ formattedUpdatedAt }}
    </p>
    <p v-if="document.description" class="px-1 max-w-lg">
      {{ document.description }}
    </p>
  </div>
  <div
    v-if="user?.is_editor || user?.is_admin"
    class="flex gap-4 flex-row mb-3"
  >
    <button
      @click="addSection"
      class="px-4 py-1 bg-paper border-2 border-black rounded-md text-sm hover:text-paper hover:bg-black duration-300 transition-colors cursor-pointer"
    >
      Add Section
    </button>
    <button
      @click="isChangeOrder = !isChangeOrder"
      class="px-4 py-1 bg-paper border-2 border-black rounded-md text-sm hover:text-paper hover:bg-black duration-300 transition-colors cursor-pointer"
    >
      Change Order
    </button>
  </div>
  <div class="flex flex-col gap-4">
    <div
      v-for="(section, index) in document.sections"
      :key="section.id"
      class="flex flex-row gap-4"
    >
      <div
        v-if="isChangeOrder && (user?.is_editor || user?.is_admin)"
        class="overflow-hidden duration-300 transition-all flex flex-col gap-2 self-center pt-2 pb-3.5 min-w-[30px]"
      >
        <button
          v-if="index != 0"
          @click="moveSection(index, -1)"
          class="p-2 rounded-md bg-gray-300 hover:bg-gray-400 duration-300 transition-colors"
        >
          <AngleIcon />
        </button>
        <button
          v-if="index != document.sections.length - 1"
          @click="moveSection(index, 1)"
          class="p-2 rounded-md bg-gray-300 hover:bg-gray-400 duration-300 transition-colors justify-self-end"
        >
          <AngleIcon class="rotate-180" />
        </button>
      </div>
      <DocumentSection
        :section="section"
        :selectedRoleId="selectedRoleId"
        :user="user"
        :roles="roles"
        @updateSelectedRoleId="changeSelectedRoleId"
      />
    </div>
  </div>
  <ConfirmDelete
    v-if="showDeleteConfirmation"
    @confirm="deleteDocument"
    @cancel="cancelDeletion"
    item="Document"
  />
</template>
