<script setup>
import { defineProps, ref, onMounted, defineEmits, computed } from "vue";
import { useStore } from "vuex";
import axios from "axios";

import SubmitButton from "../ui/SubmitButton.vue";
import ConfirmDelete from "../ui/ConfirmDelete.vue";

import SpinnerIcon from "../icons/SpinnerIcon.vue";
import TrashIcon from "../icons/TrashIcon.vue";

const props = defineProps({
  category: Object,
});

const store = useStore();
const token = computed(() => store.state.token);

const emit = defineEmits(["submitted"]);

const form = ref({
  name: "",
});

const isSubmitting = ref(false);
const submissionError = ref("");

const showDeleteConfirmation = ref(false);

const cancelDeletion = () => {
  showDeleteConfirmation.value = false;
};

const requestDeletion = () => {
  showDeleteConfirmation.value = true;
};

const deleteCategory = async () => {
  showDeleteConfirmation.value = false;

  const headers = {
    headers: {
      Authorization: `Bearer ${token.value}`,
    },
  };
  try {
    await axios.delete(`/categories/${props.category.id}`, headers);
    store.commit("DELETE_CATEGORY", { categoryId: props.category.id });
    emit("submitted");
  } catch (error) {
    console.error("Error deleting section: ", error);
  }
};

const handleSubmit = async () => {
  if (isSubmitting.value) {
    return;
  }

  isSubmitting.value = true;
  submissionError.value = "";

  const headers = {
    headers: {
      Authorization: `Bearer ${token.value}`,
    },
  };

  try {
    if (props.category) {
      let response = await axios.put(
        `/categories/${props.category.id}`,
        form.value,
        headers
      );
      store.commit("UPDATE_CATEGORY", {
        categoryId: props.category.id,
        updatedCategory: response.data,
      });
    } else {
      let response = await axios.post("/categories", form.value, headers);
      store.commit("ADD_CATEGORY", response.data);
      form.value = {
        name: "",
      };
    }
    emit("submitted");
  } catch (error) {
    console.error("Error submitting role: ", error);
    submissionError.value =
      error.response?.data?.message || "An unknown error occured";
  } finally {
    isSubmitting.value = false;
  }
};

onMounted(() => {
  if (props.category) {
    form.value.name = props.category.name;
  }
});
</script>

<template>
  <div>
    <form
      @submit.prevent="handleSubmit"
      class="grid grid-cols-1 md:grid-cols-2 gap-4"
    >
      <div class="">
        <label
          :for="props.category ? 'name-role' : 'name-role-edit'"
          class="block text-xs"
          >Name</label
        >
        <input
          :id="props.category ? 'name-role' : 'name-role-edit'"
          type="text"
          v-model="form.name"
          placeholder="General"
          class="px-2 py-1 rounded-md border-2 border-black bg-paper text-sm focus:outline-0 focus:border-gray-500 w-full"
        />
      </div>
      <div
        class="inline-flex flex-row gap-4 items-center flex-wrap sm:mt-4 mt-0"
      >
        <SubmitButton :is-disabled="form.name.trim() === '' || isSubmitting">
          <span v-if="!isSubmitting">Submit</span>
          <SpinnerIcon v-if="isSubmitting" class="animate-spin" />
        </SubmitButton>
        <button
          v-if="props.category"
          @click.prevent="emit('submitted')"
          class="bg-paper border-2 border-black text-black rounded-md px-4 py-1 hover:text-paper hover:bg-black duration-300 transition-colors"
        >
          Clear Category
        </button>
        <span>
          <TrashIcon
            v-if="props.category"
            @click="requestDeletion"
            class="text-red-800 cursor-pointer h-5"
          />
        </span>
      </div>
    </form>
    <ConfirmDelete
      v-if="showDeleteConfirmation && props.category"
      @confirm="deleteCategory"
      @cancel="cancelDeletion"
      :item="`category ${props.category.name}`"
    />
  </div>
</template>
