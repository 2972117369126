<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";

import UserForm from "@/components/forms/UserForm.vue";
import RoleForm from "@/components/forms/RoleForm.vue";

import CheckMarkIcon from "@/components/icons/CheckMarkIcon.vue";
import CategoryForm from "@/components/forms/CategoryForm.vue";

const store = useStore();

const users = computed(() => store.state.users);
const roles = computed(() => store.state.roles);
const categories = computed(() => store.state.categories);

const filteredUsers = computed(() => {
  if (!usersSearchQuery.value) return users.value;
  return users.value.filter((user) =>
    user.name.toLowerCase().includes(usersSearchQuery.value.toLowerCase())
  );
});

const filteredRoles = computed(() => {
  if (!rolesSearchQuery.value) return roles.value;
  return roles.value.filter((role) =>
    role.title.toLowerCase().includes(rolesSearchQuery.value.toLowerCase())
  );
});

const filteredCategories = computed(() => {
  if (!categorySearchQuery.value) return categories.value;
  return categories.value.filter((category) =>
    category.name
      .toLowerCase()
      .includes(categorySearchQuery.value.toLowerCase())
  );
});

const usersSearchQuery = ref("");
const rolesSearchQuery = ref("");
const categorySearchQuery = ref("");
const selectedUser = ref();
const selectedRole = ref();
const selectedCategory = ref();
const checked = ref(false);

const selectUser = (user) => {
  selectedUser.value = user;
};

const clearUser = () => {
  selectedUser.value = null;
};

const selectRole = (role) => {
  selectedRole.value = role;
};

const clearRole = () => {
  selectedRole.value = null;
};

const selectCategory = (category) => {
  selectedCategory.value = category;
};

const clearCategory = () => {
  selectedCategory.value = null;
};

onMounted(async () => {
  await store.dispatch("fetchUsers");
  await store.dispatch("fetchRoles");
  await store.dispatch("fetchCategories");
});
</script>

<template>
  <div class="flex flex-col gap-4">
    <h2 class="font-bold text-4xl">Admin Panel</h2>
    <div class="max-w-4xl bg-gray-200 px-4 py-2 pb-3 rounded-md">
      <h3 class="text-2xl font-bold mb-2">Add User</h3>
      <UserForm />
    </div>
    <div class="max-w-4xl bg-gray-200 px-4 py-2 pb-3 rounded-md">
      <h3 class="text-2xl font-bold mb-2">Edit User</h3>
      <div class="flex flex-row gap-4">
        <input
          type="text"
          v-model="usersSearchQuery"
          class="px-2 py-1 rounded-md border-2 border-black bg-paper text-sm focus:outline-0 w-full flex-grow"
          placeholder="Search for a user"
        />
        <label class="flex items-center cursor-pointer">
          <input
            v-model="checked"
            type="checkbox"
            class="peer opacity-0 absolute h-0 w-0"
          />
          <span
            class="peer-checked:bg-black border-2 border-black rounded-sm w-4 h-4 mr-2 flex items-center bg-paper justify-center"
            ><CheckMarkIcon class="text-paper"
          /></span>
          Change&nbsp;Password
        </label>
      </div>
      <div
        :class="filteredUsers.length ? 'border-2' : 'border-0'"
        class="dropdown-container bg-paper text-black border-black max-h-[160px] rounded-md mt-2 overflow-y-scroll"
      >
        <div
          v-for="user in filteredUsers"
          :key="user.id"
          @click="selectUser(user)"
          class="p-2 cursor-pointer hover:bg-gray-200"
        >
          {{ user.name }}
        </div>
      </div>
      <UserForm
        class="mt-4"
        v-if="selectedUser"
        :user="selectedUser"
        :update-password="checked"
        @submitted="clearUser"
      />
    </div>
    <div class="max-w-4xl bg-gray-200 px-4 py-2 pb-3 rounded-md">
      <h3 class="text-2xl font-bold mb-2">Add Role</h3>
      <RoleForm />
    </div>
    <div class="max-w-4xl bg-gray-200 px-4 py-2 pb-3 rounded-md">
      <h3 class="text-2xl font-bold mb-2">Edit Role</h3>
      <div class="flex flex-row gap-4">
        <input
          type="text"
          v-model="rolesSearchQuery"
          class="px-2 py-1 rounded-md border-2 border-black bg-paper text-sm focus:outline-0 w-full flex-grow"
          placeholder="Search for a role"
        />
      </div>
      <div
        :class="filteredRoles.length ? 'border-2' : 'border-0'"
        class="dropdown-container bg-paper text-black border-black max-h-[160px] rounded-md mt-2 overflow-y-scroll"
      >
        <div
          v-for="role in filteredRoles"
          :key="role.id"
          @click="selectRole(role)"
          class="p-2 cursor-pointer hover:bg-gray-200"
        >
          {{ role.title }}
        </div>
      </div>
      <RoleForm
        class="mt-4"
        v-if="selectedRole"
        :role="selectedRole"
        @submitted="clearRole"
      />
    </div>
    <div class="max-w-4xl bg-gray-200 px-4 py-2 pb-3 rounded-md">
      <h3 class="text-2xl font-bold mb-2">Add Category</h3>
      <CategoryForm />
    </div>
    <div class="max-w-4xl bg-gray-200 px-4 py-2 pb-3 rounded-md">
      <h3 class="text-2xl font-bold mb-2">Edit Category</h3>
      <div class="flex flex-row gap-4">
        <input
          type="text"
          v-model="categorySearchQuery"
          class="px-2 py-1 rounded-md border-2 border-black bg-paper text-sm focus:outline-0 w-full flex-grow"
          placeholder="Search for a category"
        />
      </div>
      <div
        :class="filteredCategories.length ? 'border-2' : 'border-0'"
        class="dropdown-container bg-paper text-black border-black max-h-[160px] rounded-md mt-2 overflow-y-scroll"
      >
        <div
          v-for="category in filteredCategories"
          :key="category.id"
          @click="selectCategory(category)"
          class="p-2 cursor-pointer hover:bg-gray-200"
        >
          {{ category.name }}
        </div>
      </div>
      <CategoryForm
        class="mt-4"
        v-if="selectedCategory"
        :category="selectedCategory"
        @submitted="clearCategory"
      />
    </div>
  </div>
</template>
