<template>
  <router-view />
</template>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600&display=swap");

body {
  background-color: #f2f2f2;
  overflow-x: hidden;
}
</style>
