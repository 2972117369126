<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from "axios";

import SubmitButton from "@/components/ui/SubmitButton.vue";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";

const route = useRoute();
const router = useRouter();
const store = useStore();

const documentId = route.params.id;
const token = computed(() => store.state.token);

const isEditing = ref(documentId ? true : false);
const isSubmitting = ref(false);
const submissionError = ref("");

const form = ref({
  title: "",
  description: "",
  categories: [],
});

const document = computed(() => store.state.currentDocument);
const categories = computed(() => store.state.categories);

const setCategory = (id) => {
  const index = form.value.categories.indexOf(id);
  if (index == -1) {
    form.value.categories.push(id);
  } else {
    form.value.categories.splice(index, 1);
  }
};

onMounted(async () => {
  if (isEditing.value) {
    await store.dispatch("fetchDocument", documentId);
    const doc = document.value;
    if (doc) {
      form.value = {
        title: doc.title,
        description: doc.description,
        categories: doc.categories.map((cat) => cat.id),
      };
    }
  }
  await store.dispatch("fetchCategories");
});

const handleSubmit = async () => {
  if (isSubmitting.value) {
    return;
  }

  isSubmitting.value = true;
  submissionError.value = "";

  let id = documentId || null;
  const headers = {
    headers: {
      Authorization: `Bearer ${token.value}`,
    },
  };
  try {
    if (isEditing.value) {
      await axios.put(`/documents/${documentId}`, form.value, headers);
    } else {
      let response = await axios.post("/documents", form.value, headers);
      id = response.data.id;
      store.commit("ADD_DOCUMENT", response.data);
    }
    router.push({ name: "document", params: { id: id } });
  } catch (error) {
    console.error("Error submitting form: ", error);
    submissionError.value =
      error.response?.data?.message || "An unknown error occured";
  } finally {
    isSubmitting.value = false;
  }
};
</script>

<template>
  <form @submit.prevent="handleSubmit" class="max-w-lg flex flex-col gap-4">
    <div class="">
      <label for="title" class="block text-xs">Title</label>
      <input
        id="title"
        type="text"
        v-model="form.title"
        placeholder="Process Document"
        class="px-2 py-1 rounded-md border-2 border-black bg-paper text-sm focus:outline-0 focus:border-gray-500 w-full"
      />
    </div>
    <div class="">
      <label for="description" class="block text-xs">Description</label>
      <textarea
        id="description"
        v-model="form.description"
        rows="4"
        max="255"
        placeholder="Add a description of your document. Max 255 Characters."
        class="px-2 py-1 rounded-md border-2 border-black bg-paper text-sm focus:outline-0 focus:border-gray-500 w-full"
      />
    </div>
    <div class="mb-1">
      <label for="categories" class="block text-xs mb-2">Categories</label>
      <div class="flex gap-4 flex-row flex-wrap">
        <div
          v-for="category in categories"
          v-bind:key="category.id"
          @click="setCategory(category.id)"
          @keyup.enter="setCategory(category.id)"
          :class="
            form.categories.includes(category.id)
              ? 'bg-black text-paper'
              : 'bg-gray-300'
          "
          class="rounded-md px-3 py-1 text-sm hover:bg-black hover:text-paper transition-colors duration-300 cursor-pointer"
          tabindex="0"
        >
          {{ category.name }}
        </div>
      </div>
    </div>
    <div class="">
      <SubmitButton
        :is-disabled="
          form.title.trim() === '' ||
          form.description.trim() === '' ||
          isSubmitting
        "
      >
        <span v-if="!isSubmitting">Submit</span>
        <SpinnerIcon v-if="isSubmitting" class="animate-spin" />
      </SubmitButton>
    </div>
    <div v-if="submissionError" class="text-red-700 text-xs">
      {{ submissionError }}
    </div>
  </form>
</template>
