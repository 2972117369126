<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import SubmitButton from "@/components/ui/SubmitButton.vue";

import EyePositiveIcon from "@/components/icons/EyePositiveIcon.vue";
import EyeNegativeIcon from "@/components/icons/EyeNegativeIcon.vue";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";

const store = useStore();
const router = useRouter();

const loginForm = ref({
  email: "",
  password: "",
});

const showPassword = ref(false);
const isLoggingIn = ref(false);
const loginError = ref("");

const isLoginDisabled = computed(() => {
  let email = loginForm.value.email.trim();
  let password = loginForm.value.password;
  return email === "" || password === "";
});

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
};

const preformLogin = async () => {
  if (isLoggingIn.value) {
    return;
  }

  isLoggingIn.value = true;
  loginError.value = "";

  try {
    await store.dispatch("login", loginForm.value);
    router.push({ name: "app" });
  } catch (error) {
    loginError.value =
      error.response?.data?.message || "An unknown error occured";
  } finally {
    isLoggingIn.value = false;
  }
};
</script>

<template>
  <div class="flex w-screen min-h-screen items-center justify-center">
    <div
      class="border-2 border-black rounded-lg m-4 px-4 py-8 max-w-xl w-full grid sm:grid-cols-2"
    >
      <div>
        <div class="flex flex-col gap-3 mb-8">
          <h1 class="text-4xl leading-6 font-thin">
            <span class="font-bold text-lg leading-0">STEPHENS</span
            ><br />Process Viewer
          </h1>
          <p class="text-sm">
            View Process Documents and Navigate Your Role in the Workflow
          </p>
        </div>
        <form @submit.prevent="preformLogin" class="flex flex-col gap-4">
          <div class="">
            <label for="email" class="block text-xs">Email</label>
            <input
              id="email"
              type="email"
              v-model="loginForm.email"
              placeholder="user@email.com"
              class="px-2 py-1 rounded-md border-2 border-black bg-paper text-sm focus:outline-0 focus:border-gray-500 w-full"
            />
          </div>
          <div class="relative">
            <label for="password" class="block text-xs">Password</label>
            <input
              id="password"
              :type="showPassword ? 'text' : 'password'"
              v-model="loginForm.password"
              placeholder="············"
              class="px-2 py-1 rounded-md border-2 border-black bg-paper text-sm focus:outline-0 focus:border-gray-500 w-full"
            />
            <div
              @click="toggleShowPassword"
              class="cursor-pointer absolute z-10 bottom-2 right-2"
            >
              <EyePositiveIcon v-if="!showPassword" class="w-5" />
              <EyeNegativeIcon v-if="showPassword" class="w-5" />
            </div>
          </div>
          <div class="">
            <SubmitButton :is-disabled="isLoggingIn || isLoginDisabled">
              <span v-if="!isLoggingIn">Login</span>
              <SpinnerIcon v-if="isLoggingIn" class="animate-spin" />
            </SubmitButton>
          </div>
          <div v-if="loginError" class="text-red-700 text-xs">
            {{ loginError }}
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
