<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ShieldIcon from "../icons/ShieldIcon.vue";
import { computed } from "vue";

const store = useStore();
const router = useRouter();

const user = computed(() => store.getters.currentUser);

const logout = () => {
  store.dispatch("logout");
  router.push("/login");
};
</script>

<template>
  <nav
    id="nav"
    class="flex flex-row justify-between sm:px-8 py-3 fixed items-center top-0 left-0 w-screen border-b-2 border-black bg-paper z-30 px-4"
  >
    <div>
      <Router-Link :to="{ name: 'app' }">
        <p class="text-2xl leading-4 font-thin pb-1.5">
          <span class="font-bold text-sm leading-0">STEPHENS</span><br />Process
          Viewer
        </p>
      </Router-Link>
    </div>
    <div class="flex gap-4 flex-row items-center">
      <RouterLink v-if="user?.is_admin" :to="{ name: 'admin' }"
        ><ShieldIcon
      /></RouterLink>
      <button
        @click="logout"
        class="border-2 border-black rounded-md px-4 bg-paper py-1 cursor-pointer hover:bg-black hover:text-paper transition-colors duration-300"
      >
        Logout
      </button>
    </div>
  </nav>
</template>
