import { createStore } from "vuex";
import axios from "axios";

axios.defaults.baseURL = "https://test.coletroester.com/api";

export default createStore({
  state: {
    user: null,
    token: localStorage.getItem("token") || null,
    documents: [],
    currentDocument: {},
    categories: [],
    roles: [],
    users: [],
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    currentUser: (state) => state.user,
    isAdmin: (state) => state.user && state.user.is_admin,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    LOGOUT(state) {
      state.token = null;
      state.user = null;
    },
    SET_DOCUMENTS(state, documents) {
      state.documents = documents;
    },
    SET_CURRENT_DOCUMENT(state, document) {
      state.currentDocument = document;
    },
    ADD_DOCUMENT(state, newDocument) {
      state.documents.push(newDocument);
    },
    ADD_USER(state, user) {
      state.users.push(user);
    },
    ADD_ROLE(state, role) {
      state.roles.push(role);
    },
    ADD_CATEGORY(state, category) {
      state.categories.push(category);
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_USERS(state, users) {
      state.users = users;
    },
    UPDATE_SECTION(state, { sectionId, updatedSection }) {
      const sectionIndex = state.currentDocument.sections.findIndex(
        (sec) => sec.id == sectionId
      );
      if (sectionIndex != -1) {
        state.currentDocument.sections[sectionIndex] = updatedSection;
      }
    },
    UPDATE_USER(state, { userId, updatedUser }) {
      const userIndex = state.users.findIndex((user) => user.id == userId);
      if (userIndex != -1) {
        state.users[userIndex] = updatedUser;
      }
    },
    UPDATE_ROLE(state, { roleId, updatedRole }) {
      const roleIndex = state.roles.findIndex((role) => role.id == roleId);
      if (roleIndex != -1) {
        state.roles[roleIndex] = updatedRole;
      }
    },
    UPDATE_CATEGORY(state, { categoryId, updatedCategory }) {
      const categoryIndex = state.categories.findIndex(
        (cat) => cat.id == categoryId
      );
      if (categoryIndex != -1) {
        state.categories[categoryIndex] = updatedCategory;
      }
    },
    DELETE_SECTION(state, { sectionId }) {
      const sectionIndex = state.currentDocument.sections.findIndex(
        (sec) => sec.id === sectionId
      );
      if (sectionIndex != -1) {
        state.currentDocument.sections.splice(sectionIndex, 1);
      }
    },
    DELETE_DOCUMENT(state, { documentId }) {
      const documentIndex = state.documents.findIndex(
        (doc) => doc.id == documentId
      );
      if (documentIndex != -1) {
        state.documents.splice(documentIndex, 1);
      }
    },
    DELETE_USER(state, { userId }) {
      const userIndex = state.users.findIndex((user) => user.id == userId);
      if (userIndex != -1) {
        state.users.splice(userIndex, 1);
      }
    },
    DELETE_ROLE(state, { roleId }) {
      const roleIndex = state.roles.findIndex((role) => role.id == roleId);
      if (roleIndex != -1) {
        state.roles.splice(roleIndex, 1);
      }
    },
    DELETE_CATEGORY(state, { categoryId }) {
      const categoryIndex = state.categories.findIndex(
        (cat) => cat.id == categoryId
      );
      if (categoryIndex != -1) {
        state.categories.splice(categoryIndex, 1);
      }
    },
  },
  actions: {
    login({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("/login", credentials)
          .then((response) => {
            const token = response.data.token;
            const user = response.data.user;
            localStorage.setItem("token", token);
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            commit("SET_TOKEN", token);
            commit("SET_USER", user);
            resolve(response);
          })
          .catch((error) => {
            localStorage.removeItem("token");
            reject(error);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit("LOGOUT");
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
    initializeAuth({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.token) {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + state.token;
          axios
            .get("/user")
            .then((response) => {
              commit("SET_USER", response.data);
              resolve(response);
            })
            .catch((error) => {
              commit("LOGOUT");
              reject(error);
            });
        } else {
          reject("No token found");
        }
      });
    },
    async fetchDocuments({ commit }) {
      try {
        const response = await axios.get("/documents");
        const sortedDocuments = response.data.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        commit("SET_DOCUMENTS", sortedDocuments);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    },
    async fetchDocument({ commit }, id) {
      const response = await axios.get(`/documents/${id}`);
      commit("SET_CURRENT_DOCUMENT", response.data);
    },
    async fetchCategories({ commit }) {
      try {
        const response = await axios.get("/categories");
        const sortedCategories = response.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        commit("SET_CATEGORIES", sortedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    async fetchRoles({ commit }) {
      try {
        const response = await axios.get("/roles");
        const sortedRoles = response.data.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        commit("SET_ROLES", sortedRoles);
      } catch (error) {
        console.error("Error fetching roles: ", error);
      }
    },
    async fetchUsers({ commit }) {
      try {
        const response = await axios.get("/users");
        const sortedUsers = response.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        commit("SET_USERS", sortedUsers);
      } catch (error) {
        console.error("Error fetching users: ", error);
      }
    },
  },
});
