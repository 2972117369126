<script setup>
import { defineEmits, defineProps } from "vue";

defineProps({
  item: String,
});

const emit = defineEmits(["confirm", "cancel"]);

const confirmDeletion = () => {
  emit("confirm");
};

const cancelDeletion = () => {
  emit("cancel");
};
</script>

<template>
  <div
    class="fixed h-screen w-screen bg-black bg-opacity-40 inset-0 z-50 flex justify-center items-center"
  >
    <div class="z-[51] bg-paper p-8 m-2 rounded-md border-2 border-black">
      <p class="text-2xl font-bold">Confirm Deletion of {{ item }}</p>
      <div class="flex gap-4 flex-row mt-4">
        <button
          @click.prevent="confirmDeletion"
          class="px-2 py-0.5 text-sm border-2 border-red-800 bg-red-800 text-white rounded-md hover:text-paper hover:bg-red-800 duration-300 transition-colors cursor-pointer"
        >
          Delete
        </button>
        <button
          @click.prevent="cancelDeletion"
          class="px-2 py-0.5 text-sm bg-paper border-2 border-black rounded-md hover:text-paper hover:bg-black duration-300 transition-colors cursor-pointer"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>
